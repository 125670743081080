/** @format */

.right-0-intro-tooltip {
  right: 0;
}

.introjs-tooltiptext {
  white-space: nowrap;
  padding: 0 20px;
}

.introjs-tooltip-header {
  padding: 0;
}

.introjs-tooltip {
  min-width: auto;
}
