/** @format */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@font-face {
  font-family: BryantRegular;
  src: url(../fonts/bryantpro-font-family/Bryant-Regular.otf);
}

@font-face {
  font-family: Bryant;
  src: url(../fonts/bryantpro-font-family/Bryant-Regular.otf);
}

@font-face {
  font-family: Bryant;
  src: url(../fonts/bryantpro-font-family/Bryant-Bold.otf);
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url(../fonts/inter-font-family/Inter.ttf);
}


body {
  margin: 0;
  font-size: 16px;
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

.rtf--mb__c {
  padding: 0 !important;
  margin: 0 !important;
}

.rtf.open .rtf--mb>* {
  transform: rotate(360deg);
  /* transform-origin: center center;
  transition: transform .2s ease-in-out; */
}
/* Animated Marker Styling */
.img-display {
  position: absolute;
}

.icon-div {
  border: none;
}

.gps-ring {
  transform: translate(-6px, -6px);
  outline: none;
  border-radius: 30px;
  height: 48px;
  width: 48px;
  padding: 5px;    
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;     
}

.stop-icon-border {
  border: 3px solid #0a2e40;
}

.office-icon-border {
  border: 3px solid #FF5959;
}

.disabled {
  cursor: context-menu;
}

@keyframes pulsate {
  0% {opacity: 0.0; transform: translate(-6px, -6px) scale(0.8, 0.8);}
  50% {opacity: 1.0;}
  100% {opacity: 0.0; transform:  translate(-6px, -6px) scale(1.3, 1.3);}
}